import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class Stages extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(4);
    }

    render(){
        const style = {
            heartStageImage: {
                "maxWidth"  : "280px",
                "margin"    : "0px auto",
                "width"     : "280px",
                "height"    : "auto",
                "padding"   : "10px",
            }
        }
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="HeartDiseaseMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <div className="SectionTitle">There are 4 main stages of valvular heart disease:<sup>1</sup><br />A, B, C, D</div>
                            </div>
                        </div>
                        <div className="ContentContainer Alt">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionHeading MarginBottomZero">Stage A</p>
                                    <div className="StagesFlex">
                                        <div className="StagesFlexText">
                                            <p className="SectionTitle">Dogs at high risk for heart disease</p>
                                            <p className="SectionText">No disease present, however if your dog is over 6 years of age or one of the "at risk breeds" it is important for your dog to have regular check-ups with your vet.</p>
                                        </div>
                                        <div className="FlexImageContainer">
                                            <Img
                                                fluid={this.props.data.stage1.childImageSharp.fluid}
                                                className="TypicalImage"
                                                style={style.heartStageImage}
                                            />
                                        </div>
                                    </div>
                                    <p className="SectionText MarginTopHalf">At risk breeds include:</p>
                                    <ul>
                                        <li className="SectionListElement">&ndash; Cavalier King Charles</li>
                                        <li className="SectionListElement">&ndash; Poodle</li>
                                        <li className="SectionListElement">&ndash; Schnauzer</li>
                                        <li className="SectionListElement">&ndash; Chihuahua</li>
                                        <li className="SectionListElement">&ndash; Fox Terrier</li>
                                        <li className="SectionListElement">&ndash; Jack Russell Terrier</li>
                                    </ul>
                                    <p className="SectionText">Dogs in the early stages (A and B) do not display any visible signs of illness. Your vet may, however, listen to your dog's heart using a stethoscope to detect a murmur, which is an indication of Stage B.</p>
                                </div>
                            </div>
                        </div>
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionHeading">Stage B</p>
                                <p className="SectionTitle">A murmur is heard but there are no visible signs of heart failure</p>
                                <div className="StagesFlex">
                                    <div className="StagesFlexText">
                                        <p className="SectionHeading">Stage B1</p>
                                        <p className="SectionText">The heart <strong>DOES NOT</strong> appear enlarged or changed on an x-ray or ultrasound (echocardiogram).</p>
                                    </div>
                                    <div className="FlexImageContainer">
                                        <Img
                                            fluid={this.props.data.stage2.childImageSharp.fluid}
                                            className="TypicalImage"
                                            style={style.heartStageImage}
                                        />
                                    </div>
                                </div>
                                <div className="StagesFlex">
                                    <div className="StagesFlexText">
                                        <p className="SectionHeading">Stage B2</p>
                                        <p className="SectionText">The heart <strong>DOES</strong> appear enlarged or changed on an x-ray or echocardiogram and treatment is necessary.</p>
                                    </div>
                                    <div className="FlexImageContainer">
                                        <Img
                                            fluid={this.props.data.stage3.childImageSharp.fluid}
                                            className="TypicalImage"
                                            style={style.heartStageImage}
                                        />
                                    </div>
                                </div>
                                <p className="SectionTitle">Early diagnosis at Stage B &ndash; when a murmur is detected</p>
                                <p className="SectionText">A murmur is an abnormal heart sound due to abnormal blood flow within the heart. This means a dog has some degree of heart disease that is affecting the heart's valves. The good news is that the dog's condition has not yet progressed to heart failure.</p>
                                <p className="SectionText">Whether your dog is in Stage B1 or Stage B2 heart disease, your vet can perform tests to look for any signs that your dog's condition is progressing and whether any treatment is required.</p>
                                <p className="SectionText">The following recommendations will help you understand the next steps for Stage B1 and Stage B2 heart disease:</p>
                                <p className="SectionTitle">Recommendations for dogs in Stage B1 &ndash; the heart is not enlarged but a murmur can be heard</p>
                                <p className="SectionText">The dog is still in the early stages of heart disease and does not yet require any medication to manage his or her condition. Your veterinarian will discuss an appropriate monitoring program for your dog at this stage of heart disease.</p>
                                <p className="SectionTitle">Recommendations for dogs in Stage B2 &ndash; a murmur can be heard and the heart is enlarged, however the dog is not in heart failure.</p>
                                <p className="SectionText">The dog is still in the early stages of heart disease; however, if the heart is sufficiently enlarged, new treatment options may be recommended.<sup>2</sup></p>
                                <p className="SectionText"> For dogs with heart disease, a change in their resting respiratory rate can be the first sign of heart failure. Talk to your vet about monitoring your dog's resting respiratory rate (RRR). Also see:<br /><Link to="/measure-health">Measure your dog's heart health</Link></p>
                                <p className="SectionTitle">Regularly monitor your dog for the following signs:</p>
                                <ul>
                                    <li className="SectionListElement">&bull; changes in breathing (increased respiratory rate, difficulty breathing, shortness of breath)</li>
                                    <li className="SectionListElement">&bull; changes in behaviour/energy level</li>
                                    <li className="SectionListElement">&bull; exercise intolerance</li>
                                    <li className="SectionListElement">&bull; fainting</li>
                                    <li className="SectionListElement">&bull; restlessness (especially at night)</li>
                                    <li className="SectionListElement">&bull; changes in appetite</li>
                                </ul>
                                <p className="SectionTitle">The goal of early treatment</p>
                                <p className="SectionText">Heart disease may not be curable, but with treatment, your vet can extend the amount of time that your dog stays in the early stages (Stage B2) of disease. This delay in the development of heart failure provides you and your best friend the chance to share more good times together.</p>
                            </div>
                        </div>
                        <div className="ContentContainer Alt">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionTitle">Stages C and D &ndash; heart failure</p>
                                    <p className="SectionText">Once the dog progresses to Stages C and D (also known as heart failure), there are visible indications of disease as the heart fails to function properly. Your vet will talk to you about the best course of treatment.</p>
                                    <div className="StagesFlex">
                                        <div className="StagesFlexText">
                                            <p className="SectionHeading">Stage C</p>
                                            <p className="SectionTitle">Evidence of heart failure is visible and treatment is necessary</p>
                                            <p className="SectionTitle">Visible signs may include:</p>
                                            <ul>
                                                <li className="SectionListElement">&bull; changes in breathing (increased respiratory rate, difficulty breathing, shortness of breath)</li>
                                                <li className="SectionListElement">&bull; changes in behaviour/energy level</li>
                                                <li className="SectionListElement">&bull; exercise intolerance</li>
                                                <li className="SectionListElement">&bull; fainting</li>
                                                <li className="SectionListElement">&bull; restlessness, especially at night</li>
                                                <li className="SectionListElement">&bull; changes in appetite</li>
                                            </ul>
                                        </div>
                                        <div className="FlexImageContainer">
                                            <Img
                                                fluid={this.props.data.stage4.childImageSharp.fluid}
                                                className="TypicalImage"
                                                style={style.heartStageImage}
                                            />
                                        </div>
                                    </div>
                                    <div className="StagesFlex MarginTopHalf">
                                        <div className="StagesFlexText">
                                            <p className="SectionHeading">Stage D</p>
                                            <p className="SectionTitle">Heart failure that is getting hard to manage and is not responding to standard treatment</p>
                                        </div>
                                        <div className="FlexImageContainer">
                                            <Img
                                                fluid={this.props.data.stage5.childImageSharp.fluid}
                                                className="TypicalImage"
                                                style={style.heartStageImage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ReferenceContainer">
                            <p className="ReferenceText">Reference:</p>
                            <p className="ReferenceText">1. Atkins C., et al. (2006) Guidelines for the diagnosis and treatment of canine chronic valvular heart disease. <em>J Vet Intern Med.</em>;23(6):1142&ndash;1150.</p>
                            <p className="ReferenceText">2. Boswood A., et al. (2016) Effect of Pimobendan in Dogs with Preclinical Myxomatous Mitral Valve Disease and Cardiomegaly: The EPIC Study-A Randomized Clinical Trial. <em>J Vet Intern Med,</em>;30(6):1765&ndash;1779.</p>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

export const query = graphql`{
    stage1: file(relativePath: { eq: "heartDisease/stage1.png" }){
        childImageSharp {
            fluid(maxWidth: 280){
                ...GatsbyImageSharpFluid
            }
        }
    }
    stage2: file(relativePath: { eq: "heartDisease/stage2.png" }){
        childImageSharp {
            fluid(maxWidth: 280){
                ...GatsbyImageSharpFluid
            }
        }
    }
    stage3: file(relativePath: { eq: "heartDisease/stage3.png" }){
        childImageSharp {
            fluid(maxWidth: 280){
                ...GatsbyImageSharpFluid
            }
        }
    }
    stage4: file(relativePath: { eq: "heartDisease/stage4.png" }){
        childImageSharp {
            fluid(maxWidth: 280){
                ...GatsbyImageSharpFluid
            }
        }
    }
    stage5: file(relativePath: { eq: "heartDisease/stage5.png" }){
        childImageSharp {
            fluid(maxWidth: 280){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Stages);
